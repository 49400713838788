export default {
  MAX_ATTACHMENT_SIZE: 5000000,
  apiGateway: {
    REGION: "us-west-2",
    URL: "https://xvd4znru08.execute-api.us-west-2.amazonaws.com/dev"
  },
  cognito: {
    REGION: "us-west-2",
    USER_POOL_ID: "us-west-2_wqQkGa7I5",
    APP_CLIENT_ID: "64m10v8ik2q0lk3408orpksqfp",
    IDENTITY_POOL_ID: "us-west-2:782594a4-727f-4f1e-acd1-7f0018cf31c1"
  }
};
